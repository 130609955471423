import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';

const ProgramAreas = () => {
  const [loading, setLoading] = useState(true);
  const [programsFeed, setProgramsFeed] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      await authorizeGetRequestWOT('program/active/0')
        .then((response) => {
          if (response.programAreas !== 0) {
            setProgramsFeed(response.programAreas)
          } else {
            setProgramsFeed([])
          }
        })
        .catch((err) => {
          setProgramsFeed([])
          console.log('programAreas error', err);
        });
      setLoading(false)
    }
    getData();
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Program Areas</title>
        <link rel="canonical" href="https://rapehurtsfoundation.org/programs" />
        <meta property="og:title" content="Program Areas at Rape Hurts Foundation" />
        <meta property="og:description"
          content="Learn More About What We Do & Get Involved in Our Areas of Interest" />
        <meta property="og:url" content="https://rapehurtsfoundation.org/programs" />
        <meta name="keywords" content="rapehurts foundation,rape hurts foundation, rhf,rapehurts, rape hurts, ngos in uganda, ugandan ngo, rhf uganda" />
      </Helmet>
      <Navbar />

      {/* intro */}
      <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">Our Areas of Interest</h1>
        </div>
      </div>
      {/* intro */}

      {/* program areas */}
      <div class="container-xxl py-5">
        <div class="container">
          {loading ? (
            <>
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
                <h1 class="display-6 mb-5">Learn More About What We Do & Get Involved</h1>
              </div>
              <div class="row g-4 justify-content-center">
                {programsFeed.length >= 1 ? (
                  <>
                    {programsFeed.map((item, index) => {
                      // const imgs = JSON.parse(item.imgs)
                      return (
                        <div key={index} class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
                            <img className="img-fluid mb-4" src={item.img} alt="" style={{ objectFit: 'cover', maxHeight: 120, minHeight: 120 }} />
                            <h4 className="mb-3">{item.title}</h4>
                            <p className="mb-4">{item.subtitle}</p>
                            <Link to={`/programs/${item.id}`} className="btn btn-outline-primary px-3">
                              Learn More
                              <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    )}
                  </>
                ) : (
                  <>
                    <h1 class="display-6 mb-5 text-primary">When available, our Program areas will appear here</h1>
                  </>
                )}

              </div>
            </>
          )}

        </div>
      </div>
      {/* program areas */}

      <Footer />
    </>
  )
}

export default ProgramAreas
