import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import carousel2 from '../assets/carousel-2.jpg'
import user from '../assets/user.jpg'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      await authorizeGetRequestWOT('pageContent/home')
        .then(response => {
          console.log('home dt', response.pageContent)
          if (response.pageContent !== 0) {
            setData(response.pageContent)
          } else {
            setData([])
          }
        })
        .catch((error) => {
          console.log('error home', error)
          setData([])
        })
      setLoading(false)
    }
    getData()
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rape Hurts Foundation</title>
        <link rel="canonical" href="https://rapehurtsfoundation.org" />
        <meta property="og:title" content="Rape Hurts Foundation" />
        <meta property="og:description"
          content="We provide free, expert, comprehensive, and compassionate care for sexual assault victims – children and adults – 24 hours a day." />
        <meta property="og:image"
          content="https://api.rapehurtsfoundation.org/campaigns/rhf3.jpg" />
        <meta property="og:url" content="https://rapehurtsfoundation.org" />
        <meta name="keywords" content="rapehurts foundation,rape hurts foundation, rhf,rapehurts, rape hurts, ngos in uganda, ugandan ngo, rhf uganda" />
      </Helmet>
      {loading ? (
        <>
          <div class="loader m-5 text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navbar />

          {/* carousel */}
          <div className="container-fluid p-0 mb-5">
            <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                  {data[0] && (
                    <>
                      {JSON.parse(data[0].content).map((item, index) => (
                        <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                          <img className="w-100" src={item.img} alt="Img" />
                          <div className="carousel-caption">
                            <div className="container">
                              <div className="row justify-content-center">
                                <div className="col-lg-7 pt-5">
                                  <h1 className="display-4 text-white mb-3 animated slideInDown">{item.title}</h1>
                                  <p className="fs-5 text-white-50 mb-5 animated slideInDown">{item.caption}</p>
                                  <Link to="/campaigns" className="btn btn-primary py-2 px-3 animated slideInDown">
                                    Learn More
                                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                      <i className="fa fa-arrow-right"></i>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          {/* carousel */}

          {/* about */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                  {data[1] && (
                    <>
                      <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="position-relative overflow-hidden h-100" style={{ minHeight: 400 }}>
                          <img className="position-absolute w-100 h-100 pt-5 pe-2" src={JSON.parse(data[1].content).img} alt="" style={{ objectFit: 'cover' }} />
                        </div>
                      </div>
                      <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="h-100">
                          <Link to="/about" className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">About Us</Link>
                          <h1 className="display-6 mb-5">{JSON.parse(data[1].content).title}</h1>
                          <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                            <p className="text-dark mb-2">{JSON.parse(data[1].content).founderCaption}</p>
                            <span className="text-primary">Hellen, Founder</span>
                          </div>
                          <p className="mb-5">{JSON.parse(data[1].content).description}</p>
                          <Link to="/about" className="btn btn-primary py-2 px-3 me-3">
                            Learn More
                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </Link>
                          <Link to="/contact" className="btn btn-outline-primary py-2 px-3">
                            Contact Us
                            <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                
              </div>
            </div>
          </div>
          {/* about */}

          {/* program areas */}
          <div className="container-xxl bg-light py-5">
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
                <Link to="/programs" className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Program Areas</Link>
                <h1 className="display-6 mb-5">Learn More About The Topics We Focus On</h1>
              </div>
              <div className="row g-4 justify-content-center">
                {data[2] && (
                  <>
                      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                          <img className="img-fluid mb-4" src={data[2].pga1.img} alt="" style={{ objectFit: 'cover', maxHeight: 120, minHeight: 120 }} />
                          <h4 className="mb-3">{data[2].pga1.title}</h4>
                          <p className="mb-4">{data[2].pga1.subtitle}</p>
                          <Link to={`/programs/${data[2].pga1.id}`} className="btn btn-outline-primary px-3">
                            Learn More
                            <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                          <img className="img-fluid mb-4" src={data[2].pga2.img} alt="" style={{ objectFit: 'cover', maxHeight: 120, minHeight: 120 }} />
                          <h4 className="mb-3">{data[2].pga2.title}</h4>
                          <p className="mb-4">{data[2].pga2.subtitle}</p>
                          <Link to={`/programs/${data[2].pga2.id}`} className="btn btn-outline-primary px-3">
                            Learn More
                            <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                          <img className="img-fluid mb-4" src={data[2].pga3.img} alt="" style={{ objectFit: 'cover', maxHeight: 120, minHeight: 120 }} />
                          <h4 className="mb-3">{data[2].pga3.title}</h4>
                          <p className="mb-4">{data[2].pga3.subtitle}</p>
                          <Link to={`/programs/${data[2].pga3.id}`} className="btn btn-outline-primary px-3">
                            Learn More
                            <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                  </>
                )}
                
              </div>
            </div>
          </div>
          {/* program areas */}

          {/* campaigns */}
          <div className="container-xxl my-5 py-5">
            <div className="container py-5">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
                <Link to="/campaigns" className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Campaigns</Link>
                <h1 className="display-6 mb-5">Here's a few projects we are working on</h1>
              </div>
              <div className="row g-4 justify-content-center">
                  {data[3] && (
                    <>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                              <small>{data[3].pga1.category}</small>
                      </div>
                      <h5 className="mb-3">{data[3].pga1.title}</h5>
                            <p>{data[3].pga1.subtitle}</p>
                      
                    </div>
                    <div className="position-relative mt-auto">
                            <img className="img-fluid" src={JSON.parse(data[3].pga1.imgs).img1} alt="" />
                      <div className="causes-overlay">
                              <Link to={`/campaigns/${data[3].pga1.id}`} className="btn btn-outline-primary">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                              <small>{data[3].pga2.category}</small>
                      </div>
                            <h5 className="mb-3">{data[3].pga2.title}</h5>
                            <p>{data[3].pga2.subtitle}</p>
                      
                    </div>
                    <div className="position-relative mt-auto">
                            <img className="img-fluid" src={JSON.parse(data[3].pga2.imgs).img1} alt="" />
                      <div className="causes-overlay">
                              <Link to={`/campaigns/${data[3].pga2.id}`} className="btn btn-outline-primary" href="">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                              <small>{data[3].pga3.category}</small>
                      </div>
                            <h5 className="mb-3">{data[3].pga3.title}</h5>
                            <p>{data[3].pga3.subtitle}</p>
                      
                    </div>
                    <div className="position-relative mt-auto">
                            <img className="img-fluid" src={JSON.parse(data[3].pga3.imgs).img1} alt="" />
                      <div className="causes-overlay">
                              <Link to={`/campaigns/${data[3].pga3.id}`} className="btn btn-outline-primary" href="">
                          Read More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                    </>
                  )}
              </div>
            </div>
          </div>
          {/* campaigns */}

          {/* donate */}
          <div className="container-fluid donate my-5 py-5" data-parallax="scroll" data-image-src={carousel2}>
            <div className="container py-5">
              <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Donate Now</div>
                  <h1 className="display-6 text-white mb-5">Thanks For The Love & Support You Show</h1>
                  <p className="text-white-50 mb-0">Please be aware that all PayPal donations will be received by our U.S. partner organization, the Solar Electric Light Fund (SELF). Funds will be used to support RHF’s operating expenses to enable us to participate in our joint project efforts</p>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div className="h-100 bg-white p-5">
                    <form>
                      <div className="row g-3">
                        <div className="col-12">
                          <div className="form-floating">
                            <input type="text" className="form-control bg-light border-0" id="name" placeholder="Your Name" />
                            <label for="name">Your Names</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input type="email" className="form-control bg-light border-0" id="email" placeholder="Your Email" />
                            <label for="email">Your Email</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="btn-group d-flex justify-content-around">
                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" />
                            <label className="btn btn-light py-3" for="btnradio1">$10</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                            <label className="btn btn-light py-3" for="btnradio2">$100</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" />
                            <label className="btn btn-light py-3" for="btnradio3">$1000</label>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-floating">
                            <input type="email" class="form-control bg-light border-0" id="email" placeholder="Your Donation Amount" />
                            <label for="email">Your Donation Amount</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button type="button" onClick={() => {
                            window.location.href = 'https://www.paypal.com/donate/?hosted_button_id=YSV3G9JYRQSNG'
                          }} className="btn btn-primary px-5" style={{ height: 60 }}>
                            Donate Now
                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                              <i className="fa fa-arrow-right"></i>
                            </div>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* donate */}

          {/* Testimonial */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Testimonial</div>
                <h1 className="display-6 mb-5">Trusted By Thousands Of People And Nonprofits</h1>
              </div>
              <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                <div className="testimonial-item text-center">
                  <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={user} style={{ width: 100, height: 100 }} />
                  <div className="testimonial-text rounded text-center p-4">
                    <p>Words seemed to make it visible… Speaking, even when it embarrassed me, also slowly freed me from the shame
                      I’d felt. The more I struggled to speak, the less power the rape and its aftermath seemed to have over me.</p>
                    <h5 className="mb-1">Irene Munola</h5>
                    <span className="fst-italic">"kept it a secret for 21yrs"</span>
                  </div>
                </div>
                <div className="testimonial-item text-center">
                  <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={user} style={{ width: 100, height: 100 }} />
                  <div className="testimonial-text rounded text-center p-4">
                    <p>As soon as he said, 'Go in the back' I thought I was going to die. He had a knife pointed at me. He was only there for 20 minutes – but he changed my life forever.</p>
                    <h5 className="mb-1">Sarah</h5>
                    <span className="fst-italic">"At first, I thought he was a customer"</span>
                  </div>
                </div>
                <div className="testimonial-item text-center">
                  <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={user} style={{ width: 100, height: 100 }} />
                  <div className="testimonial-text rounded text-center p-4">
                    <p>At my University, all of the athletes – men and women shared training facilities.We got to know each other very well. one of the star football players invited me to a party at his apartment that night, he offered me a glass of wine, The next thing I remember is waking up 10 hours later on the floor completely naked,I felt sore in certain parts of my body</p>
                    <h5 className="mb-1">Akello Christine</h5>
                    <span className="fst-italic">"I was mortified. I knew I had been raped"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Testimonial */}

          <Footer />
        </>

      )}
    </>
  )

}

export default Home
