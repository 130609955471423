import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/Error';
import Home from './pages/Home';
import Campaigns from './pages/Campaigns';
import ProgramAreas from './pages/ProgramAreas';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Content from './pages/Content';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <Home />,
      errorElement: <Error />,
    },
    {
      path: "/about",
      element: <Content page="about" />,
      errorElement: <Error />,
    },
    {
      path: "/story/hellen",
      element: <Content page="story" />,
      errorElement: <Error />,
    },
    {
      path: "/campaigns",
      element: <Campaigns />,
      errorElement: <Error />,
    },
    {
      path: "/campaigns/:id",
      element: <Content page="campaignscontent" />,
      errorElement: <Error />,
    },
    {
      path: "/programs",
      element: <ProgramAreas />,
      errorElement: <Error />,
    },
    {
      path: "/programs/:id",
      element: <Content page="programscontent" />,
      errorElement: <Error />,
    },
    {
      path: "/contact",
      element: <Contact />,
      errorElement: <Error />,
    },
    {
      path: "/donate",
      element: <Donate />,
      errorElement: <Error />,
    },
    {
      path: "/privacy-policy",
      element: <Content page="privacy-policy" />,
      errorElement: <Error />,
    },
    {
      path: "/terms",
      element: <Content page="terms" />,
      errorElement: <Error />,
    },
    {
      path: "/blog",
      element: <Content page="blog" />,
      errorElement: <Error />,
    },
    {
      path: "/faq",
      element: <Content page="faq" />,
      errorElement: <Error />,
    },
    {
      path: "/guidelines",
      element: <Content page="guidelines" />,
      errorElement: <Error />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
