import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';

const Campaigns = () => {
  const [loading, setLoading] = useState(false);
  const [campaignsFeed, setCampaignsFeed] = useState([])
  let counter = 1;

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      await authorizeGetRequestWOT('campaign/active/0')
        .then((response) => {
          // console.log('response.campaigns', response.campaigns)
          if (response.campaigns !== 0) {
            setCampaignsFeed(response.campaigns)
          } else {
            setCampaignsFeed([])
          }
        })
        .catch((err) => {
          setCampaignsFeed([])
          console.log('programAreas error', err);
        });
      setLoading(false)
    }
    getData();
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Campaigns</title>
        <link rel="canonical" href="https://rapehurtsfoundation.org/campaigns" />
        <meta property="og:title" content="Campaigns at Rape Hurts Foundation" />
        <meta property="og:description"
          content="Find On-going Community Empowerment Projects For Women & Children in Uganda" />
        <meta property="og:image"
          content="https://api.rapehurtsfoundation.org/campaigns/vocational.jpg" />
        <meta property="og:url" content="https://rapehurtsfoundation.org/campaigns" />
        <meta name="keywords" content="rapehurts foundation,rape hurts foundation, rhf,rapehurts, rape hurts, ngos in uganda, ugandan ngo, rhf uganda" />
      </Helmet>
      <Navbar />

      {/* intro */}
      <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">On-going Community Projects</h1>
        </div>
      </div>
      {/* intro */}

      {/* campaigns */}
      <div class="container-xxl py-5">
        <div class="container">
          {loading ? (
            <>
              <div class="loader m-5 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
            <h1 class="display-6 mb-5">Empowerment Projects For Women & Children </h1>
          </div>
                {campaignsFeed.length >= 1 ? (
                  <div class="row g-4 justify-content-center">
                    {campaignsFeed.map((item, index) => {
                      const imgs = JSON.parse(item.imgs)
                      counter = counter + 2
                      console.log('counter', counter)
                      let val
                      if (counter === 3){
                        val = 1
                      } else if (counter === 5) {
                        val = 3
                      } else {
                        val = 5
                        counter = 1
                      }
                      return (
                        <div key={index} class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={`0.${val}s`}>
                          <div class="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div class="text-center p-4 pt-0">
                              <div class="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>{item.category}</small>
                              </div>
                              <h5 class="mb-3">{item.title}</h5>
                              <p>{item.subtitle}</p>
                            </div>
                            <div class="position-relative mt-auto">
                              <img class="img-fluid" src={imgs.img1} alt="" />
                              <div class="causes-overlay">
                                <Link to={`/campaigns/${item.id}`} className="btn btn-outline-primary" href="">
                                  Read More
                                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                    <i className="fa fa-arrow-right"></i>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                    }
                    )}
                  </div>
                ) : (
                  <>
                    <h1 class="display-6 mb-5 text-primary text-center">When available, our campaigns will appear here</h1>
                  </>
                )}
              </>
          )}
        </div>
      </div>
      {/* campaigns */}

      <Footer />
    </>
  )
}

export default Campaigns
