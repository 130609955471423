import { useRouteError, Link } from "react-router-dom";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Error = () => {
  const error = useRouteError();
  // console.log('error', error);
  return (
    <>
      <Navbar />

      {/* intro */}
      <div class="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center">
          <h1 class="display-4 text-white animated slideInDown mb-4">{`${error.status} Error`}</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb justify-content-center mb-0">
              <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
              <li class="breadcrumb-item"><a class="text-white" href="#">Problem Occured</a></li>
              <li class="breadcrumb-item text-primary active" aria-current="page">{`${error.status} Error`}</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* intro */}

      {/* 404 message */}
      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 class="display-1">{error.status}</h1>
              <h1 class="mb-4">{error.statusText || error.message}</h1>
              <p class="mb-4">{error.data}</p>
              <Link class="btn btn-outline-primary py-2 px-3" to="/">
                Go Back To Home
                <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                  <i class="fa fa-arrow-right"></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* 404 message */}

      <Footer />

    </>
  )
}

export default Error
